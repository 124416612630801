import * as React from "react"
import { FC } from "react"
import BasketProvider from "./BasketProvider"
import LocationProvider from "./LocationProvider"
import SearchProvider from "./SearchProvider"
import { ComponentProvider } from "@social-supermarket/social-supermarket-components"
import GLink from "../../gatsby/GLink"

interface Props {
  children: React.ReactNode
}

const GlobalProvider: FC<Props> = ({ children }) => {
  return (
    <LocationProvider>
      <SearchProvider>
        <ComponentProvider context={{ Link: GLink }}>
          <BasketProvider>{children}</BasketProvider>
        </ComponentProvider>
      </SearchProvider>
    </LocationProvider>
  )
}

export default GlobalProvider
