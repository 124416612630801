import * as React from "react"
import { FC } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ImageType from "../../social-supermarket/model/ImageType"
import GImage from "../GImage"
import GLink from "../GLink"

interface Props {}

const GLogo: FC<Props> = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `)

  const image: ImageType = {
    alt: "Social Supermarket Logo",
    gatsbyImageData: data.file.childImageSharp.gatsbyImageData,
  }

  return (
    <GLink to="/">
      <GImage image={image} style={{ width: 80 }} />
    </GLink>
  )
}

export default GLogo
