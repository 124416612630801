export const get = async url => {
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then(ar => ar.json())
}

export const deleteRequest = (url, body) => {
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  }).then(ar => ar.json())
}

export const post = async (url, body, args) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
    ...args,
  }).then(ar => ar.json())
}

export const postNoCreds = async (url, body, args) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    ...args,
  })
}

export const getJson = url => {
  return fetch(url, {
    method: "GET",
  }).then(ar => ar.json())
}

export const getNoCreds = url => {
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(ar => ar.json())
}
