import * as React from "react"
import styled from "styled-components"
import ContactForm from "../../components/form/contact-details/ContactDetailsForm"
import AddressForm from "../../components/form/address/AddressForm"
import AddressesAreTheSameField from "./fields/AddressesAreTheSameField"
import { FC } from "react"
import { CheckoutFormStateType } from "./CheckoutPage"

const SectionHeader = styled.h1`
  font-size: 1.2em;
`

interface Props {
  formState: CheckoutFormStateType
  onFormChange: (form: string, name: string, value: string | boolean | number) => void
  validate: boolean
  isGiftCardOrder: boolean
}

const CheckoutForm: FC<Props> = ({ formState, onFormChange, validate, isGiftCardOrder }) => {
  return (
    <>
      {!isGiftCardOrder && (
        <div>
          <SectionHeader>Shipping Address</SectionHeader>
          <AddressForm
            state={formState.shippingAddress}
            validate={validate}
            onChange={(name, value) => onFormChange("shippingAddress", name, value)}
            disableCountry
          />
        </div>
      )}
      <div>
        <SectionHeader>Your Contact Info</SectionHeader>
        <ContactForm
          state={formState.contactInfo}
          validate={validate}
          onChange={(name, value) => onFormChange("contactInfo", name, value)}
        />
      </div>
      <div>
        <SectionHeader>Billing Address</SectionHeader>
        {!isGiftCardOrder && (
          <AddressesAreTheSameField
            onChange={value => onFormChange("billingSame", "billingSame", value)}
            state={formState.billingSame}
          />
        )}
        {(!formState.billingSame || isGiftCardOrder) && (
          <AddressForm
            validate={validate}
            state={formState.billingAddress}
            onChange={(name, value) => onFormChange("billingAddress", name, value)}
            disableCountry={false}
          />
        )}
      </div>
    </>
  )
}

export default CheckoutForm
