import * as React from "react"
import { Context, useState } from "react"
import { DEFAULT_LOCATION_TYPE, LocationContextType } from "../model/LocationContextType"
import { Location } from "@reach/router"
import * as queryString from "query-string"

export const LocationContext: Context<LocationContextType> = React.createContext<
  LocationContextType
>({
  ...DEFAULT_LOCATION_TYPE,
})

interface Props {
  children: React.ReactNode
}

const LocationProvider = ({ children }: Props) => {
  return (
    <Location>
      {({ location }) => {
        const params = location.search ? queryString.parse(location.search) : {}
        const queryParams = {}
        Object.keys(params).forEach(key => {
          queryParams[key] = params[key]
        })
        return (
          <LocationContext.Provider value={{ location, queryParams }}>
            {children}
          </LocationContext.Provider>
        )
      }}
    </Location>
  )
}

export default LocationProvider
