import * as React from "react"
import { useEffect, useRef, useState } from "react"
import ImageType from "../../model/ImageType"
import styled from "styled-components"
import GImage from "../../../gatsby/GImage"

const Container = styled.div`
  width: 100%;
  height: ${({ width }) => (width ? `${width}px` : "unset")};
  position: relative;
  outline: none;
  border: ${({ inFocus }) => (inFocus ? "1px solid #99ddd8" : "none")};
  box-shadow: ${({ inFocus }) => (inFocus ? "0 0 10px #99ddd8" : "none")};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "inherit")};

  .tss-image-primary {
    opacity: 1;
  }
  .tss-image-secondary {
    opacity: 0;
  }

  &:hover {
    .tss-image-primary {
      opacity: ${({ hasSecond }) => (hasSecond ? "0" : "1")};
    }
    .tss-image-secondary {
      opacity: 1;
    }
  }
`

const ImageContainer = styled.div`
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

interface Props {
  image: ImageType
  secondImage?: ImageType
  onClick?: () => void
  inFocus?: boolean
}

const FlipImage = ({ image, secondImage, onClick, inFocus }: Props) => {
  const containerEl = useRef(null)
  const [width, setWidth] = useState<number>(null)

  useEffect(() => {
    setWidth(containerEl.current?.offsetWidth)
  }, [containerEl])

  return (
    <Container
      ref={containerEl}
      width={width}
      onClick={onClick}
      inFocus={inFocus}
      hasSecond={secondImage}
    >
      <ImageContainer className="tss-image-primary">
        <GImage image={image} style={{ height: "100%", width: "100%" }} objectFit="contain" />
      </ImageContainer>
      {secondImage && (
        <ImageContainer className="tss-image-secondary">
          <GImage
            image={secondImage}
            style={{ height: "100%", width: "100%" }}
            objectFit="contain"
          />
        </ImageContainer>
      )}
    </Container>
  )
}

export default FlipImage
