export const lookupPostcode = postcode => {
  const cleanPostCode = postcode.replace(" ", "")
  const url = `https://api.getAddress.io/find/${cleanPostCode}?api-key=k0hadcUbB0m4Qj4MOfzHSg27511`

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then(ar => ar.json())
    .then(data => {
      if (data.addresses && data.addresses.length > 0) {
        return { ...data, addresses: mapAddresses(data.addresses) }
      } else {
        return null
      }
    })
}

const mapAddresses = addresses => {
  return addresses.map(address => {
    const addressLines = address.split(", ")
    const city = addressLines[5]
    let address1 = ""
    let address2 = ""

    if (addressLines[0] && !addressLines[1]) {
      address1 = addressLines[0]
    }

    if (addressLines[0] && addressLines[1] && !addressLines[2]) {
      address1 = `${addressLines[0]}, ${addressLines[1]}`
    }

    if (addressLines[0] && addressLines[1] && addressLines[2] && !addressLines[3]) {
      address1 = `${addressLines[0]}, ${addressLines[1]}, ${addressLines[2]}`
    }

    if (addressLines[0] && addressLines[1] && addressLines[2] && addressLines[3]) {
      address1 = `${addressLines[0]}, ${addressLines[1]}`
      address2 = `${addressLines[2]}, ${addressLines[3]}`
    }

    return {
      address1,
      address2,
      city,
    }
  })
}
