import * as React from "react"
import { Context, FC, useEffect, useState } from "react"
import BasketType, { DEFAULT_BASKET } from "../model/BasketType"
import { BasketContextType, DEFAULT_BASKET_CONTEXT_TYPE } from "../model/BasketContextType"
import * as basketApi from "../api/basketApi"
import * as suggestionApi from "../api/suggestionApi"
import * as tracker from "../tracking/tracker"
import { isOrderSuccessPage, isRunTime, isUndefined, lockScroll } from "../util/generalUtil"
import { isGiftCard } from "../../utils/general"
import GiftCardType from "../model/GiftCardType"
import { updateGiftCardUsage } from "../pages/basket/BasketTotals"
import { CheckoutFormStateType, DEFAULT_CHECKOUT_FORM_STATE } from "../pages/checkout/CheckoutPage"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { ProductType, VariantType } from "@social-supermarket/social-supermarket-model"

interface Props {
  children: React.ReactNode
}

export const BasketContext: Context<BasketContextType> = React.createContext<BasketContextType>({
  ...DEFAULT_BASKET_CONTEXT_TYPE,
})

const BasketProvider: FC<Props> = ({ children }) => {
  const { search } = useLocation()
  const { cartKey } = queryString.parse(search)

  const [checkoutFormState, setCheckoutFormState] = useState<CheckoutFormStateType>({
    ...DEFAULT_CHECKOUT_FORM_STATE,
  })
  const [basket, setBasket] = useState<BasketType>(DEFAULT_BASKET)
  const [basketInitialised, setBasketInitialised] = useState<boolean>(false)
  const [giftCards, setGiftCards] = useState<GiftCardType[]>([])
  const [suggestions, setSuggestions] = useState<ProductType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [giftNote, updateGiftNote] = useState<string>("")
  const [deliveryNote, updateDeliveryNote] = useState<string>("")
  const [subscribe, updateSubscribe] = useState<boolean>(false)
  const [queryId, setQueryId] = useState<string>("")

  useEffect(() => {
    const init = async () => {
      if (isRunTime()) {
        if (!isOrderSuccessPage()) {
          await fetchBasket()
          setBasketInitialised(true)
        }
        setIsLoading(false)
      }
    }
    init()
  }, [])

  const fetchBasket = async () => {
    const basket = await basketApi.get(cartKey ? `${cartKey}` : undefined)
    setBasket(basket)
    suggestionApi
      .getSuggestions(basket.items.map(item => item.product.productId))
      .then(suggestions => setSuggestions(suggestions))
  }

  const toggle = (open: boolean) => {
    const newOpen = isUndefined(open) ? !isOpen : open
    setIsOpen(newOpen)
    lockScroll(newOpen)
  }

  const toggleSubscribe = () => {
    updateSubscribe(!subscribe)
  }

  const clearBasket = async () => {
    setIsLoading(true)
    await basketApi.clear()
    await fetchBasket()
    setGiftCards([])
    updateGiftNote("")
    updateDeliveryNote("")
    setIsLoading(false)
  }

  const addProduct = async (
    product: ProductType,
    variant: VariantType,
    quantity: number,
    meta: object,
    refresh: boolean = true
  ) => {
    setIsLoading(true)
    try {
      await basketApi.add(product.productId, variant, quantity, meta)
      tracker.addToBasket(product, variant, quantity, queryId)
      if (refresh) {
        await fetchBasket()
      }
    } catch (e) {
      toggle(false)
      alert(`Unable to add ${product.name}, out of stock`)
    } finally {
      setIsLoading(false)
    }
  }

  const removeProduct = async (
    basketItemKey: string,
    product: ProductType,
    refresh: boolean = true,
    variant: VariantType
  ) => {
    setIsLoading(true)
    await basketApi.remove(basketItemKey)
    tracker.removeFromBasket(product, variant)
    if (refresh) {
      await fetchBasket()
    }
    setIsLoading(false)
  }

  const updateProduct = async (
    product: ProductType,
    variant: VariantType,
    basketItemKey: string,
    quantity: number,
    meta: object,
    refresh: boolean = true
  ) => {
    setIsLoading(true)
    try {
      await basketApi.remove(basketItemKey)
      await basketApi.add(product.productId, variant, quantity, meta)
      if (refresh) {
        await fetchBasket()
      }
    } catch (e) {
      toggle(false)
      alert(e)
    }
    setIsLoading(false)
  }

  const increaseQuantity = async (basketItemKey: string) => {
    setIsLoading(true)
    const item = basket.items.find(item => item.key === basketItemKey)
    await basketApi.updateQuantity(basketItemKey, item.quantity + 1)
    await fetchBasket()
    setIsLoading(false)
  }

  const reduceQuantity = async (basketItemKey: string) => {
    setIsLoading(true)
    const item = basket.items.find(item => item.key === basketItemKey)
    if (item.quantity - 1 < 1) {
      await removeProduct(basketItemKey, item.product, false, item.variant)
    } else {
      await basketApi.updateQuantity(basketItemKey, item.quantity - 1)
      await fetchBasket()
    }
    setIsLoading(false)
  }

  const updateQuantity = async (
    basketItemKey: string,
    quantity: number,
    refresh: boolean = true
  ): Promise<void> => {
    setIsLoading(true)
    await basketApi.updateQuantity(basketItemKey, quantity)
    if (refresh) {
      await fetchBasket()
    }
    setIsLoading(false)
  }

  const applyCoupon = async (code: string, setError: (error: string) => void) => {
    setIsLoading(true)
    try {
      if (isGiftCard(code)) {
        if (!giftCards.some(gc => gc.code === code)) {
          const balance = await basketApi.getGiftCardBalance(code)
          const giftCard = { code, balance, usage: 0 }
          setGiftCards(prev => updateGiftCardUsage([...prev, giftCard], basket.total))
        }
      } else {
        await basketApi.applyCoupon(code)
        await fetchBasket()
      }
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const removeCoupons = async () => {
    setIsLoading(true)
    setGiftCards([])
    await basketApi.removeCoupons(basket.coupons)
    await fetchBasket()
    setIsLoading(false)
  }

  const removeGiftCards = async () => {
    setGiftCards([])
  }

  useEffect(() => {
    setGiftCards(prev => updateGiftCardUsage([...prev], basket.total))
  }, [basket])

  return (
    <BasketContext.Provider
      value={{
        basket,
        giftCards,
        suggestions,
        isLoading,
        isOpen,
        giftNote,
        subscribe,
        basketInitialised,
        toggleSubscribe,
        deliveryNote,
        updateGiftNote,
        updateDeliveryNote,
        toggle,
        updateProduct,
        addProduct,
        removeProduct,
        clearBasket,
        increaseQuantity,
        reduceQuantity,
        updateQuantity,
        applyCoupon,
        removeCoupons,
        removeGiftCards,
        fetchBasket,
        checkoutFormState,
        setCheckoutFormState,
        queryId,
        setQueryId,
      }}
    >
      {children}
    </BasketContext.Provider>
  )
}

export default BasketProvider
