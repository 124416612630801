import * as React from "react"
import styled from "styled-components"
import CheckoutItem from "./CheckoutItem"

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  padding: 10px 0;
`

export const CheckoutContents = ({ items }) => {
  const normalItems = items?.filter(item => item.product.slug !== "eco-friendly-gift-card") || []
  const giftCardItems = items?.filter(item => item.product.slug === "eco-friendly-gift-card") || []

  return (
    <Container>
      {normalItems.map(item => (
        <CheckoutItem item={item} key={item.key} />
      ))}
      {giftCardItems.map(item => (
        <CheckoutItem item={item} key={item.key} />
      ))}
    </Container>
  )
}

export default CheckoutContents
