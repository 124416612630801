import * as React from "react"
import styled from "styled-components"
import AddressType from "../../../model/AddressType"
import FirstNameField from "./fields/FirstNameField"
import LastNameField from "./fields/LastNameField"
import PostCodeField from "./fields/PostCodeField"
import Address1Field from "./fields/Address1Field"
import Address2Field from "./fields/Address2Field"
import CompanyField from "./fields/CompanyField"
import CityField from "./fields/CityField"
import CountryField from "./fields/CountryField"

export const DEFAULT_ADDRESS_STATE: AddressType = {
  contactEmail: "",
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  company: "",
  postCode: "",
  city: "",
  country: "GB",
  addressOptions: [],
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;

  button:last-of-type {
    margin-bottom: 20px;
  }
`

const Row = styled.div`
  display: flex;
`

const Left = styled.div`
  flex: 1;
  margin-right: 5px;
`

const Right = styled.div`
  flex: 1;
  margin-left: 5px;
`

const fields = [
  FirstNameField,
  LastNameField,
  PostCodeField,
  Address1Field,
  Address2Field,
  CompanyField,
  CityField,
  CountryField,
]

interface Props {
  onChange: (name: string, value: number | string | boolean) => void
  onBlur?: (name: string) => void
  state: AddressType
  validate: boolean
  disableCountry: boolean
}

const AddressForm = ({ onChange, onBlur, state, validate, disableCountry }: Props) => {
  return (
    <Form>
      <Row>
        <Left>
          <FirstNameField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
        </Left>
        <Right>
          <LastNameField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
        </Right>
      </Row>
      <PostCodeField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
      <Address1Field onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
      <Address2Field onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
      <CompanyField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
      <CityField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
      <CountryField
        onChange={onChange}
        onBlur={onBlur}
        state={state}
        validate={validate}
        disabled={disableCountry}
      />
    </Form>
  )
}

AddressForm.isValid = state => {
  return fields.every(Field => Field.isValid(state))
}

export default AddressForm
