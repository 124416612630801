export const grey = "#f7f9f7"
export const offGreyLight = "#d9d9d9"
export const borderGrey = "#ebeced"
export const primaryDark = "#105d6e"
export const primaryLight = "#2faeaf"
export const mustard = "#dbac48"
export const rouge = "#c7566f"
export const offWhite = "#fffef4"
export const lightGray = "#f2f2f2"
export const darkGrey = "#a3a4a5"
export const darkBorderGrey = "#6b6c6d"
export const text = "hsla(0,0%,0%,0.8)"
