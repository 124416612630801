import * as React from "react"
import { Form, Search } from "semantic-ui-react"
import { useState } from "react"
import styled from "styled-components"
import { primaryLight } from "../../constants/colors"

const Container = styled.div`
  position: relative;
  margin-bottom: 20px;

  input {
    padding: 15px 15px !important;
    width: 100% !important;
    border-radius: 5px !important;
    transition: all 0.2s ease-in-out !important;
    outline: none;
    border: 1px solid #dddddd;
    padding: 10px;

    &:focus {
      box-shadow: 0 0 3px ${primaryLight} !important;
      border: 1px solid ${primaryLight} !important;
    }
  }
`

const Label = styled.label`
  position: absolute;
  z-index: 1;
  padding: 0 7px;
  line-height: 15px;
  top: 17px;
  left: 10px;
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.1s;
  background-color: white;
  border-radius: 5px;
  cursor: text;
  pointer-events: none;

  ${({ hasValue }) => hasValue && "top: -8px; font-size: 0.9em;"}
`

interface ResultType {
  title: string
}

interface Props {
  value: string
  name: string
  label: string
  isValid: boolean
  validate: boolean
  onChange: (name: string, value: string, userSelected: boolean) => void
  onBlur: (name: string) => {}
  options: string[]
}

const SearchInput = ({
  name,
  value,
  label,
  isValid,
  validate,
  onChange,
  onBlur,
  options,
}: Props) => {
  const [results, setResults] = useState<ResultType[]>([])
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const handleSelection = (e, { result }) => {
    onChange(name, result.title, true)
    setShowDropdown(false)
  }

  const handleChange = (e, { value }) => {
    onChange(name, value, false)

    const results = value
      ? options
          .filter(option => option.toLowerCase().includes(value.toLowerCase()))
          .map(option => ({ title: option }))
      : []

    setResults(results)
    setShowDropdown(results.length > 0)
  }

  const handleFocus = () => {
    setHasFocus(true)
  }

  const handleBlur = () => {
    setHasFocus(false)
    onBlur && onBlur(name)
  }

  return (
    <Container>
      <Search
        id={name}
        loading={false}
        onResultSelect={handleSelection}
        onSearchChange={handleChange}
        results={results}
        value={value}
        autoComplete={"new-password"}
        name={name}
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
        icon=""
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <Label htmlFor={name} hasValue={value || hasFocus}>
        <div>{label}</div>
      </Label>
    </Container>
  )
}

export default SearchInput
