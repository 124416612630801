import * as React from "react"
import { FC } from "react"
import { Checkbox, Form } from "semantic-ui-react"

interface Props {
  state: boolean
  onChange: (param: boolean) => void
}

const AddressesAreTheSameField: FC<Props> = ({ onChange, state }) => (
  <Form>
    <Form.Field>
      <Checkbox
        radio
        label="Same as shipping address"
        name="billingSame"
        checked={state}
        onClick={() => onChange(true)}
      />
    </Form.Field>
    <Form.Field>
      <Checkbox
        radio
        label="Provide a different billing address"
        checked={!state}
        onClick={() => onChange(false)}
      />
    </Form.Field>
  </Form>
)

export default AddressesAreTheSameField
