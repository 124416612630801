import * as React from "react"
import FormFieldProps from "../../FormFieldProps"
import { useState } from "react"
import OutlinedInput from "../../OutlinedInput"
import LinkButton from "../../../button/LinkButton"
import { Icon } from "semantic-ui-react"
import styled from "styled-components"
import { linkBlue } from "../../../../constants/colors"
import AddressType from "../../../../model/AddressType"

const Button = styled(LinkButton)`
  text-align: left;
  margin-bottom: 5px;

  i {
    color: ${linkBlue};
    margin-right: 10px;
  }
`

const CompanyField = ({ onChange, onBlur, state, validate }: FormFieldProps<AddressType>) => {
  const [show, setShow] = useState<boolean>(false)

  return show ? (
    <OutlinedInput
      name="company"
      onChange={onChange}
      onBlur={onBlur}
      value={state.company}
      label="Company"
      validate={false}
      type="text"
      isValid={CompanyField.isValid(state)}
    />
  ) : (
    <Button onClick={() => setShow(true)}>
      <Icon name="plus circle" size="small" />
      Add Company
    </Button>
  )
}

CompanyField.isValid = state => {
  return true
}

export default CompanyField
