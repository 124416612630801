export const black = "#000000"
export const lightBlack = "#1D1D1B"
export const offBlack = "#303030"
export const grey = "#f7f9f7"
export const lightGrey = "#979797"
export const greyLight = "#e0ded8"
export const greyDark = "#7f7f7f"
export const offGrey = "#d8d8d8"
export const offGreyLight = "#d9d9d9"
export const borderGrey = "#ebeced"
export const otherBorderGrey = "#dddddd"
export const primaryDark = "#105d6e"
export const primaryLight = "#2faeaf"
export const skyBlue = "#36A9AD"
export const mustard = "#E2B238"
export const rouge = "#c7566f"
export const white = "#ffffff"
export const offWhite = "#fffef4"
export const lightGray = "#f2f2f2"
export const darkGrey = "#a3a4a5"
export const darkBorderGrey = "#6b6c6d"
export const text = "hsla(0,0%,0%,0.8)"
export const linkBlue = "#4183c4"
export const darkBlue = "#00328c"
export const backgroundGray = "#f5f3f6"
export const ocher = "#f0be48"
export const cyan = "#00bac5"
export const lightPink = "#f66588"
export const lighterBlack = "#c4c4bf"
export const textBlack = "#555555"
export const lightMustard = "#e2b238"
export const unselectedTextGrey = "rgb(136, 136, 136)"
