import BasketType from "../model/BasketType"

declare global {
  interface Window {
    AWIN: any
  }
}

export const orderSuccessPage = () => {
  console.log("DELETING AWIN VARIABLE")
  // window.AWIN = undefined
}

export const orderSuccess = (orderId: number, basket: BasketType) => {
  const voucher = basket.coupons && basket.coupons.length > 0 ? basket.coupons[0].code : ""

  if (typeof window.AWIN != "undefined" && typeof window.AWIN.Tracking != "undefined") {
    console.log("SENDING AWIN VARIABLE")
    window.AWIN.Tracking.Sale = {}
    window.AWIN.Tracking.Sale.amount = basket.total.toFixed(2)
    window.AWIN.Tracking.Sale.channel = "aw"
    window.AWIN.Tracking.Sale.orderRef = `${orderId}`
    window.AWIN.Tracking.Sale.parts = `DEFAULT:${basket.total}`
    window.AWIN.Tracking.Sale.currency = "GBP"
    window.AWIN.Tracking.Sale.voucher = voucher
    window.AWIN.Tracking.Sale.test = "0"
    window.AWIN.Tracking.run()
  }

  // console.log("SETTING AWIN VARIABLE")
  // window.AWIN = {}
  // window.AWIN.Tracking = {}
  // window.AWIN.Tracking.Sale = {}
  //
  // /*** Set your transaction parameters ***/
  // window.AWIN.Tracking.Sale.amount = `${basket.total}`
  // window.AWIN.Tracking.Sale.orderRef = `${orderId}`
  // window.AWIN.Tracking.Sale.parts = `DEFAULT:${basket.total}`
  // window.AWIN.Tracking.Sale.voucher = voucher
  // window.AWIN.Tracking.Sale.currency = "GBP"
  // window.AWIN.Tracking.Sale.test = "0"
  // window.AWIN.Tracking.Sale.channel = "aw"
}
