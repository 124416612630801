import * as React from "react"
import { Icon, SemanticICONS } from "semantic-ui-react"
import styled from "styled-components"
import { grey, rouge } from "../../constants/colors"

const Container = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom || "20px"};
  position: relative;
  flex: 1;
  font-size: 0.8em;

  i {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`

const Label = styled.label`
  position: absolute;
  z-index: 1;
  padding: 0 7px;
  line-height: 15px;
  top: 17px;
  left: 10px;
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.1s;
  background-color: white;
  border-radius: 5px;
  cursor: text;
  pointer-events: none;

  ${({ hasValue }) => hasValue && "top: -8px; font-size: 0.9em;"}
`

const Input = styled.input`
  border-radius: 5px;
  padding: 15px 15px !important;
  width: 100%;
  ${({ valid }) => (!valid ? `border-color: ${rouge} !important;` : "")}

  &:focus + label {
    top: -8px;
    font-size: 0.9em;
  }

  &:disabled {
    background-color: ${grey};
  }
`

interface Props {
  name: string
  onChange: (name: string, value: string) => void
  value: string | number
  label?: string
  icon?: SemanticICONS
  isValid: boolean
  validate: boolean
  disabled?: boolean
  optional?: boolean
  type?: string
  onBlur?: (name: string) => void
  autoComplete?: string
  placeholder?: string
  marginBottom?: string
  className?: string
  min?: number
}

const OutlinedInput = ({
  name,
  onChange,
  value,
  label,
  icon,
  isValid,
  validate,
  disabled,
  type = "text",
  onBlur,
  autoComplete = "",
  placeholder = "",
  marginBottom,
  optional,
  className,
  min,
}: Props) => {
  const valid = validate === false || isValid === undefined || isValid

  return (
    <Container marginBottom={marginBottom} className={className}>
      <Input
        id={name}
        name={name}
        onChange={event => onChange(event.target.name, event.target.value)}
        value={value}
        disabled={disabled}
        type={type}
        onBlur={() => onBlur && onBlur(name)}
        valid={valid}
        autoComplete={autoComplete}
        placeholder={placeholder}
        min={min}
      />
      <Label htmlFor={name} hasValue={value}>
        <div>
          {label} {optional && <span>(optional)</span>}
        </div>
      </Label>
      {icon && <Icon name={icon} />}
    </Container>
  )
}

export default OutlinedInput
