import * as api from "../../api/general"
import { getConfig } from "../../config/index"
import { ProductType } from "@social-supermarket/social-supermarket-model"

export const getSuggestions = async (productIds: number[]): Promise<ProductType[]> => {
  if (productIds.length > 0) {
    const result = await api.get(
      `${
        getConfig().baseUrl
      }/wp-json/social-supermarket/v1/cart-suggestions?products=${productIds.join(",")}`
    )
    return result.map(res => mapProduct(res))
  } else {
    return Promise.resolve([])
  }
}

const mapProduct = (result: any): ProductType => {
  const brands = result.brands.nodes.map(({ name, slug }) => ({
    name,
    slug,
  }))
  const variants = result.variations?.nodes
    ? result.variations?.nodes.map(({ price }) => ({
        price: parseFloat(price),
      }))
    : undefined
  return {
    productId: result.productId,
    slug: result.slug,
    name: result.name,
    sku: result.sku,
    price: result.price,
    date: result.date,
    image: {
      src: result.imageSrc,
      alt: "",
    },
    gallery: result.galleryImagesSrc.map(imgSrc => ({
      src: imgSrc,
    })),
    brand: brands.length ? brands[0] : undefined,
    variants,
  }
}
