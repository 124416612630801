import * as qs from "query-string"
import { useCallback, useState } from "react"
import { isRunTime } from "../util/generalUtil"

const setQueryStringWithoutPageReload = qsValue => {
  const newurl =
    window.location.protocol + "//" + window.location.host + window.location.pathname + qsValue

  window.history.replaceState({ path: newurl }, "", newurl)
}

const setQueryStringValue = (key, value, queryString: string = window.location.search) => {
  const values = qs.parse(queryString)
  const newQsValue = qs.stringify({ ...values, [key]: value })
  setQueryStringWithoutPageReload(`?${newQsValue}`)
}

export const getQueryStringValue = (key, queryString?: string): string => {
  if (!queryString && isRunTime()) {
    queryString = window.location.search
  } else if (!queryString) {
    queryString = ""
  }
  const values = qs.parse(queryString)
  // @ts-ignore
  return values[key]
}

export const getSerlialiseStringValue = (key, queryString?: string): string => {
  if (!queryString && isRunTime()) {
    queryString = window.location.search
  } else if (!queryString) {
    queryString = ""
  }
  const values = qs.parse(queryString)
  // @ts-ignore
  return values[key] ? JSON.parse(values[key]) : undefined
}

const setSerlialiseStringValue = (key, value, queryString: string = window.location.search) => {
  const values = qs.parse(queryString)
  const newQsValue = qs.stringify({ ...values, [key]: JSON.stringify(value) })
  setQueryStringWithoutPageReload(`?${newQsValue}`)
}

const setObjectStringValue = (baseKey: string, objectValue: object, queryString?: string) => {
  if (!queryString && isRunTime()) {
    queryString = window.location.search
  } else if (!queryString) {
    queryString = ""
  }

  const values = { ...qs.parse(queryString) }
  const newValues = {}

  Object.keys(values).forEach(key => {
    if (!key.includes(baseKey)) {
      newValues[key] = values[key]
    }
  })

  Object.keys(objectValue).forEach(key => {
    const urlKey = `${baseKey}_${key}`
    newValues[urlKey] = objectValue[key]
  })

  const newQsValue = qs.stringify(newValues)
  setQueryStringWithoutPageReload(`?${newQsValue}`)
}

export const getAllQueryStringValue = (
  baseKey,
  queryString,
  parse: boolean = false
): { [key: string]: any } => {
  if (!queryString && isRunTime()) {
    queryString = window.location.search
  } else if (!queryString) {
    queryString = ""
  }

  const values: { [key: string]: any } = qs.parse(queryString)

  const result: { [key: string]: any } = {}

  Object.keys(values).forEach(key => {
    if (key.includes(baseKey)) {
      const mainKey = key.replace(`${baseKey}_`, "")
      result[mainKey] = parse ? JSON.parse(values[key]) : values[key]
    }
  })

  return Object.keys(result).length > 0 ? result : null
}

export const useQueryString = (key, initialValue) => {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue)
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue)
      setQueryStringValue(key, newValue)
    },
    [key]
  )

  return [value, onSetValue]
}

export const useObjectQueryString = (
  baseKey,
  initialValue,
  parse?: boolean
): [object, (object) => void] => {
  const [value, setValue] = useState<object>(
    getAllQueryStringValue(baseKey, undefined, parse) || initialValue
  )

  const onSetValue = useCallback(
    newValue => {
      setValue(newValue)
      setObjectStringValue(baseKey, newValue)
    },
    [baseKey]
  )

  return [value, onSetValue]
}

export const useSerializeQueryString = (key, initialValue): [object, (object) => void] => {
  const [value, setValue] = useState<object>(
    getSerlialiseStringValue(key, undefined) || initialValue
  )

  const onSetValue = useCallback(
    newValue => {
      setValue(newValue)
      setSerlialiseStringValue(key, newValue)
    },
    [key]
  )

  return [value, onSetValue]
}

export default useQueryString
