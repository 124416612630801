import * as React from "react"
import styled from "styled-components"
import * as colors from "../../constants/colors"

const LinkButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${colors.linkBlue};
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    border: none !important;
    outline: none;
  }
`

export default LinkButton
