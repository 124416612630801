import * as React from "react"
import OutlinedInput from "../../OutlinedInput"
import FormFieldProps from "../../FormFieldProps"
import { validPhoneNumberFormat } from "../../../../constants/validation"
import ContactDetailsType from "../../../../model/ContactDetailsType"

const PhoneField = ({ onChange, onBlur, state, validate }: FormFieldProps<ContactDetailsType>) => {
  return (
    <OutlinedInput
      name="phone"
      onChange={onChange}
      value={state.phone}
      label="Phone"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={PhoneField.isValid(state)}
    />
  )
}

PhoneField.isValid = ({ phone }) => {
  return phone && validPhoneNumberFormat.test(phone)
}

export default PhoneField
