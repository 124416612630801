import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ImageType from "../social-supermarket/model/ImageType"
import UrlImage from "../social-supermarket/components/image/UrlImage"

interface Props {
  image: ImageType
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down"
  style?: object
  fullSize?: boolean
  sizes?: string
  className?: string
}

const GImage = ({ image, style, objectFit, fullSize, sizes, className }: Props) => {
  if (image?.gatsbyImageData) {
    return (
      <GatsbyImage
        image={image.gatsbyImageData}
        alt={image.alt}
        style={style}
        imgStyle={{ objectFit, maxWidth: "unset" }}
        className={className}
      />
    )
  } else {
    return (
      <UrlImage
        image={image}
        style={style}
        objectFit={objectFit}
        fullSize={fullSize}
        sizes={sizes}
        className={className}
      />
    )
  }
}

export default GImage
