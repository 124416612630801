import * as React from "react"
import OutlinedInput from "../../OutlinedInput"
import FormFieldProps from "../../FormFieldProps"
import AddressType from "../../../../model/AddressType"

const CityField = ({ onChange, onBlur, state, validate }: FormFieldProps<AddressType>) => {
  return (
    <OutlinedInput
      name="city"
      onChange={onChange}
      value={state.city}
      label="City"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={CityField.isValid(state)}
    />
  )
}

CityField.isValid = state => {
  return state.city
}

export default CityField
