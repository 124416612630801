import * as api from "../../api/general"
import { getConfig } from "../../config/index"
import { BasketContextType } from "../model/BasketContextType"
import { getGiftCardUse } from "../pages/basket/BasketTotals"
import { CheckoutFormStateType } from "../pages/checkout/CheckoutPage"
import { debounce } from "../util/generalUtil"

export const initCheckout = async (basketContext: BasketContextType) => {
  const giftCardAmount = getGiftCardUse(basketContext.giftCards, basketContext.basket.total)
  return api.post(`${getConfig().baseUrl}/wp-json/social-supermarket/v1/checkout/create`, {
    items: basketContext.basket.items,
    cartHash: basketContext.basket.hash,
    cartKey: basketContext.basket.key,
    coupons: basketContext.basket.coupons,
    deliveryNote: basketContext.deliveryNote,
    giftNote: basketContext.giftNote,
    shippingMethod: basketContext.basket.shippingMethod,
    subscribe: basketContext.subscribe,
    giftCards: basketContext.giftCards,
  })
}

export const updateCheckout = async (
  basketKey: string,
  checkoutFormState: CheckoutFormStateType,
  sourceTracking: any
) => {
  return api.post(`${getConfig().baseUrl}/wp-json/social-supermarket/v1/checkout/update`, {
    cartKey: basketKey,
    ...checkoutFormState,
    sourceTracking,
  })
}

export const completeCheckout = async (basketKey: string) => {
  return api.post(`${getConfig().baseUrl}/wp-json/social-supermarket/v1/checkout/complete`, {
    cartKey: basketKey,
  })
}
