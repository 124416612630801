import { parsePrice } from "./general"

export const getCheapestShipping = methods => {
  const methodList = Object.values(methods)
  let cheapestPrice = null
  let cheapestMethod = null

  for (let i = 0; i < methodList.length; i++) {
    const method = methodList[i]
    const price = parsePrice(method.cost)
    if (cheapestPrice === null || price < cheapestPrice) {
      cheapestPrice = price
      cheapestMethod = method
    }
  }
  return cheapestMethod
}

export const getStandardShipping = methods => {
  return methods["table_rate:14"]
}
