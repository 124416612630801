import * as React from "react"
import { Link } from "gatsby"

interface Props {
  to: string
  children: React.ReactNode
  onClick?: (event: any) => void
  className?: string
  target?: string
}

const GLink = ({ to, children, onClick, className = "", target }: Props) => {
  return (
    <Link to={to} onClick={onClick} className={className} target={target}>
      {children}
    </Link>
  )
}

export default GLink
