import { WindowLocation } from "@reach/router"
import { ParsedQuery } from "query-string"

export type LocationContextType = {
  location: WindowLocation
  queryParams: { [key: string]: string }
}

export const DEFAULT_LOCATION_TYPE: LocationContextType = {
  location: null,
  queryParams: {},
}
