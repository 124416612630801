export default interface ShippingMethod {
  id: number
  methodId: string
  key: string
  label: string
  total: number
  tax: number
}

export const DEFAULT_SHIPPING_METHOD: ShippingMethod = {
  id: 0,
  methodId: "",
  key: "",
  label: "",
  total: 0,
  tax: 0,
}
