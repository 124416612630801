import sbjs from "sourcebuster"
import he from "he"

export const isRunTime = (): boolean => {
  return typeof window !== "undefined"
}

export const isOrderSuccessPage = (): boolean => {
  return typeof window !== "undefined" && window.location.href.includes("order-success")
}

export const safeHeDecode = (text: string | null) => {
  return he.decode(text || "")
}
export const isUndefined = (object): boolean => {
  return typeof object !== "undefined"
}

export const lockScroll = (lock: boolean): void => {
  document.documentElement.style.overflowY = lock ? "hidden" : "auto"
  document.documentElement.style.height = lock ? "100%" : "100%"
  // document.body.style.overflowY = lock ? "hidden" : "auto"
  // document.body.style.height = lock ? "100%" : "auto"
}

export const equalsIgnoreCase = (s1, s2) => {
  if (!s1 && !s2) {
    return true
  }

  if ((!s1 && s2) || (!s2 && s1)) {
    return false
  }
  return cleanString(s1) === cleanString(s2)
}

export const getSourceTrackingInfo = () => {
  if (sbjs?.get) {
    const { current, first } = sbjs.get
    return {
      first: {
        utmSource: first.src,
        utmMedium: first.mdm,
        utmCampaign: first.cmp,
        utmContent: first.cnt,
        utmTerm: first.trm,
      },
      current: {
        utmSource: current.src,
        utmMedium: current.mdm,
        utmCampaign: current.cmp,
        utmContent: current.cnt,
        utmTerm: current.trm,
      },
    }
  } else {
    return { first: {}, current: {} }
  }
}

export const getRegistrationLink = () => {
  const { utmSource, utmMedium, utmCampaign, utmTerm } = getSourceTrackingInfo().current
  return `https://app.socialsupermarket.org/register?utm_term=${utmTerm}&utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_medium=${utmMedium}`
}

export const debounce = (func, timeout = 300) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const isANumber = (thing): boolean => {
  return !isNotANumber(thing)
}
export const isNotANumber = (thing): boolean => {
  return isNaN(parseFloat(thing))
}

export const cleanString = (name: string): string => {
  return name
    .replace(/[.,\/#!$%\^&\*;:{}=\_`~()]/g, "")
    .replace(/ /g, "-")
    .toLowerCase()
}

export const deepEquals = (a, b) => JSON.stringify(a) === JSON.stringify(b)

export const cleanUrl = url => {
  if (
    !url.includes("gifting.socialsupermarket.org") &&
    !url.includes("app.socialsupermarket.org")
  ) {
    return url.replace(/^.*\/\/[^\/]+/, "")
  } else {
    return url
  }
}

export const getMonth = date => {
  return ("0" + (date.getMonth() + 1)).slice(-2)
}
