import * as React from "react"
import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { navigate } from "gatsby"
import SEO from "../../../components/Seo"
import AddressForm, { DEFAULT_ADDRESS_STATE } from "../../components/form/address/AddressForm"
import { offGreyLight, rouge } from "../../constants/colors"
import CheckoutContents from "./CheckoutContents"
import Spinner from "../../components/spinner/Spinner"
import ContactDetailsForm, {
  DEFAULT_CONTACT_DETAILS_STATE,
} from "../../components/form/contact-details/ContactDetailsForm"
import { Columns, InnerColumn, LeftColumn, RightColumn } from "../../../components/layout/split"
import * as tracker from "../../tracking/tracker"
import { BasketContext } from "../../context/BasketProvider"
import { desktop } from "../../../constant/screenSizes"
import Payment from "./Payment"
import CheckoutForm from "./CheckoutForm"
import * as checkoutApi from "../../api/checkoutApi"
import AddressType from "../../model/AddressType"
import ContactDetailsType from "../../model/ContactDetailsType"
import { debounce, deepEquals, getSourceTrackingInfo } from "../../util/generalUtil"
import { PrimaryButton } from "@social-supermarket/social-supermarket-components"
import GLogo from "../../../gatsby/components/GLogo"

const Body = styled.div`
  margin: 0 auto;
  position: relative;
`
const ContentsContainer = styled.div`
  border-bottom: 1px solid ${offGreyLight};
`
const LogoContainer = styled.div`
  margin-bottom: 20px;
  max-width: 100px;
`
const SubmitButtonContainer = styled.div`
  max-width: 300px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: auto;

  @media (max-width: ${desktop}px) {
    width: 100%;
    max-width: unset;
  }
`
const CardError = styled.div`
  color: ${rouge};
  text-align: right;
  font-size: 0.9em;
  min-height: 35px;
`

export interface CheckoutFormStateType {
  shippingAddress: AddressType
  billingAddress: AddressType
  contactInfo: ContactDetailsType
  billingSame: boolean
}

export const DEFAULT_CHECKOUT_FORM_STATE = {
  shippingAddress: { ...DEFAULT_ADDRESS_STATE },
  billingAddress: { ...DEFAULT_ADDRESS_STATE },
  contactInfo: { ...DEFAULT_CONTACT_DETAILS_STATE },
  billingSame: true,
}

const updateCheckoutDebounce = debounce(checkoutApi.updateCheckout, 1000)

const CheckoutPageNew = () => {
  const [orderId, setOrderId] = useState<number>()
  const [loading, setLoading] = useState(true)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [clientSecret, setClientSecret] = useState("")
  const [cardError, setCardError] = useState("")
  const [submitError, setSubmitError] = useState("")
  const [validate, setValidate] = useState(false)
  const basketContext = useContext(BasketContext)
  const { basket, basketInitialised, checkoutFormState, setCheckoutFormState } = basketContext

  const formState = checkoutFormState
    ? checkoutFormState
    : {
        ...DEFAULT_CHECKOUT_FORM_STATE,
      }

  const isGiftCardOrder = basket?.items?.every(item => item.giftCard && item.giftCard.email)

  const billingAddress =
    formState.billingSame && !isGiftCardOrder
      ? { ...formState.shippingAddress }
      : formState.billingAddress

  const stripe = useStripe()

  const elements = useElements()

  const backendFormState = {
    shippingAddress: formState.shippingAddress,
    billingAddress,
    contactInfo: formState.contactInfo,
    billingSame: formState.billingSame,
  }

  const isFormValid =
    (isGiftCardOrder || AddressForm.isValid(formState.shippingAddress)) &&
    (formState.billingSame || AddressForm.isValid(formState.billingAddress)) &&
    ContactDetailsForm.isValid(formState.contactInfo)

  useEffect(() => {
    if (basketInitialised) {
      initCheckout()
    }
  }, [basketInitialised])

  useEffect(() => {
    updateCheckoutDebounce(basket.key, backendFormState, getSourceTrackingInfo())
  }, [checkoutFormState])

  const initCheckout = async () => {
    if (!basket?.items?.length) {
      navigate(`/basket`)
      return
    }

    tracker.startCheckout(basket)

    try {
      const { status, checkout } = await checkoutApi.initCheckout(basketContext)
      if (status !== "SUCCESS") {
        navigate(`/basket`)
        return
      }

      setOrderId(checkout.orderId)
      setClientSecret(checkout.clientSecret)

      const billingSame = deepEquals(checkout.shippingAddress, checkout.billingAddress)

      setCheckoutFormState({
        billingSame,
        shippingAddress: { ...DEFAULT_ADDRESS_STATE, ...checkout.shippingAddress, country: "GB" },
        billingAddress: billingSame
          ? { ...DEFAULT_ADDRESS_STATE }
          : { ...DEFAULT_ADDRESS_STATE, ...checkout.billingAddress },
        contactInfo: {
          email: checkout.email,
          phone: checkout.phone,
        },
      })

      setLoading(false)
    } catch (e) {
      navigate(`/basket`)
    }
  }

  const handleFormChange = (form: string, name: string, value) => {
    if (name === "billingSame") {
      setCheckoutFormState(prev => ({ ...prev, billingSame: value }))
    } else {
      setCheckoutFormState(prev => ({ ...prev, [form]: { ...prev[form], [name]: value } }))
    }
  }

  const handleCardChange = event => {
    setCardError(event.error ? event.error.message : "")
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (!isFormValid) {
      handleSubmitError(`Please fill in required fields.`)
      return
    }

    if (cardError) {
      setValidate(true)
      return
    }

    setPaymentLoading(true)

    await checkoutApi.updateCheckout(basket.key, backendFormState, getSourceTrackingInfo())

    if (!clientSecret) {
      completeOrder()
    } else {
      const stripeResponse = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: `${billingAddress.firstName} ${billingAddress.lastName}`,
            email: formState.contactInfo.email,
            address: {
              city: billingAddress.city,
              line1: billingAddress.address1,
              line2: billingAddress.address2,
              postal_code: billingAddress.postCode,
            },
          },
        },
      })

      if (!stripeResponse.error) {
        tracker.orderSuccess(orderId, basket, formState)
        navigate(`/order-success?orderId=${orderId}`)
      } else {
        setSubmitError(`Payment failed: ${stripeResponse.error.message}`)
        setPaymentLoading(false)
        setValidate(true)
      }
    }
  }

  const handleSubmitError = message => {
    setSubmitError(message)
    setPaymentLoading(false)
    setValidate(true)
  }

  const completeOrder = async () => {
    setPaymentLoading(true)
    try {
      const response = await checkoutApi.completeCheckout(basket.key)
      if (response.status === "SUCCESS") {
        tracker.orderSuccess(response.orderId, basket, formState)
        navigate(`/order-success?orderId=${response.orderId}`)
      } else {
        handleSubmitError(response.message)
      }
    } catch (error) {
      handleSubmitError(error)
    }
  }

  return (
    <>
      <SEO title={"Checkout"} description={"Social Supermarket checkout page."} />
      <Spinner isLoading={loading} label="Initialising secure checkout.." />
      <Spinner
        isLoading={paymentLoading}
        label="Completing order, please do not close this window or press the back button."
      />
      {!loading && (
        <Body>
          <Columns>
            <LeftColumn>
              <InnerColumn>
                <LogoContainer>
                  <GLogo />
                </LogoContainer>
                <CheckoutForm
                  formState={formState}
                  onFormChange={handleFormChange}
                  validate={validate}
                  isGiftCardOrder={isGiftCardOrder}
                />
              </InnerColumn>
            </LeftColumn>
            <RightColumn>
              <InnerColumn>
                <ContentsContainer>
                  <CheckoutContents items={basket.items} />
                </ContentsContainer>
                <Payment clientSecret={clientSecret} handleCardChange={handleCardChange} />
                <SubmitButtonContainer>
                  <PrimaryButton fullWidth onClick={handleSubmit}>
                    {`Confirm & ${clientSecret ? "Pay" : "Submit"}`}
                  </PrimaryButton>
                </SubmitButtonContainer>
                <CardError>{validate && cardError + " " + submitError}</CardError>
              </InnerColumn>
            </RightColumn>
          </Columns>
        </Body>
      )}
    </>
  )
}

export default CheckoutPageNew
