import * as api from "../../api/general"
import { getConfig } from "../../config/index"
import BasketType from "../model/BasketType"
import { parsePrice } from "../../utils/general"
import Coupon from "../model/Coupon"
import { mapAttributes, mapBasket } from "../../gatsby/transformers/basketTransformer"
import { VariantType } from "@social-supermarket/social-supermarket-model"

export const get = async (cartKey?: string): Promise<BasketType> => {
  let cart
  if (cartKey) {
    cart = await api.getNoCreds(
      `${getConfig().baseUrl}/wp-json/cocart/v1/get-cart?thumb=true&cart_key=${cartKey}`
    )
  } else {
    cart = await api.get(`${getConfig().baseUrl}/wp-json/cocart/v1/get-cart?thumb=true`)
  }

  let shippingMethods
  try {
    shippingMethods = await api.post(
      `${getConfig().baseUrl}/wp-json/cocart/v1/calculate/shipping`,
      {
        country: "GB",
        return_methods: true,
      }
    )
  } catch (e) {
    shippingMethods = [
      {
        key: "",
        label: "",
        total: 0,
        tax: 0,
      },
    ]
  }
  return mapBasket(cart, shippingMethods)
}

export const add = async (
  productId: number,
  variant: VariantType,
  quantity: number,
  meta: object
): Promise<any> => {
  const body: any = {
    product_id: `${productId}`,
    quantity: quantity,
    return_cart: true,
    cart_item_data: meta,
  }

  if (variant) {
    body.variation_id = `${variant.variantId}`
    body.variation = mapAttributes(variant.attributes)
  }

  const response = await api.post(
    `${getConfig().baseUrl}/wp-json/cocart/v1/add-item?thumb=true`,
    body
  )

  if (response.code === "cocart_product_out_of_stock") {
    throw "This product is currently out of stock"
  } else if (response.code) {
    throw "Unexpected error adding to basket"
  }

  return response
}

export const updateQuantity = async (key: string, quantity: number): Promise<any> => {
  const response = await api.post(`${getConfig().baseUrl}/wp-json/cocart/v1/item?thumb=true`, {
    cart_item_key: key,
    quantity: quantity,
    return_cart: true,
  })

  if (response.code) {
    throw response.message
  }

  return response
}

export const remove = async (key: string): Promise<any> => {
  const response = await api.deleteRequest(
    `${getConfig().baseUrl}/wp-json/cocart/v1/item?thumb=true`,
    {
      cart_item_key: key,
      return_cart: true,
    }
  )

  if (response.code) {
    throw response.message
  }

  return response
}

export const getGiftCardBalance = async (coupon: string): Promise<number> => {
  const balance = await api.post(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/gift-card/redeem`,
    {
      coupon,
    }
  )

  if (balance.code) {
    throw balance.message
  }

  return parsePrice(balance)
}

export const applyCoupon = async (coupon: string): Promise<void> => {
  const response = await api.post(`${getConfig().baseUrl}/wp-json/cocart/v1/coupon`, {
    coupon,
  })

  if (response.code) {
    throw response.message
  }

  return response
}

export const removeCoupons = async (coupons: Coupon[]): Promise<void[]> => {
  return Promise.all(coupons.map(({ code }) => removeCoupon(code)))
}

export const removeCoupon = async (coupon: string): Promise<void> => {
  const response = await api.deleteRequest(`${getConfig().baseUrl}/wp-json/cocart/v1/coupon`, {
    coupon,
  })

  if (response.code) {
    throw response.message
  }

  return response
}

export const clear = async () => {
  const response = await api.post(`${getConfig().baseUrl}/wp-json/cocart/v1/clear`)

  if (response.code) {
    throw response.message
  }

  return response
}
