import * as React from "react"
import styled from "styled-components"
import { FC, useEffect, useRef, useState } from "react"
import ImageType from "../../model/ImageType"
import { isRunTime } from "../../util/generalUtil"

const Container = styled.div``

const Img = styled.img<{ isLoaded: boolean; isBuild: boolean }>`
  object-fit: ${({ objectFit }) => objectFit};
  transition: opacity 0.2s ease-in-out;
  opacity: ${({ isLoaded, isBuild }) => (isLoaded || isBuild ? 1 : 0)};
`

interface Props {
  image: ImageType
  style?: object
  objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down"
  fullSize?: boolean
  sizes?: string
  className?: string
}

const UrlImage: FC<Props> = ({ image, style, objectFit, fullSize, sizes, className }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const imageRef = useRef(null)

  useEffect(() => {
    if (imageRef.current?.complete) {
      setIsLoaded(true)
    }
  }, [])

  const handleLoad = () => {
    setIsLoaded(true)
  }
  return (
    <Img
      src={image.src}
      srcSet={fullSize ? null : image.srcSet}
      sizes={sizes}
      alt={image.alt}
      style={style}
      objectFit={objectFit}
      onLoad={handleLoad}
      isLoaded={isLoaded}
      ref={imageRef}
      isBuild={!isRunTime()}
      className={className}
    />
  )
}

export default UrlImage
