import BasketType from "../model/BasketType"

declare global {
  interface Window {
    tap: any
  }
}

export const orderSuccess = (orderId: number, basket: BasketType) => {
  if (typeof window.tap !== `undefined`) {
    window.tap("conversion", orderId, basket.total)
  }
}
