import styled from "styled-components"
import { desktop, largeDesktop, tablet } from "../../constant/screenSizes"
import { grey } from "../../constant/colors"

export const Columns = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;

  @media (max-width: ${largeDesktop}px) {
  }

  @media (max-width: ${desktop}px) {
    flex-direction: column;
  }
`

export const LeftColumn = styled.div`
  margin-right: 40px;
  padding-top: 20px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 40px;

  @media (max-width: ${largeDesktop}px) {
    padding-left: 20px;
    margin-right: 20px;
  }

  @media (max-width: ${desktop}px) {
    width: 100%;
    padding: 25px;
  }

  @media (max-width: ${tablet}px) {
    padding: 15px;
  }
`

export const RightColumn = styled.div`
  padding-left: 40px;
  padding-top: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${grey};
  border-left: 1px solid #d8d8d8;
  padding-bottom: 40px;
  position: relative;

  @media (max-width: ${largeDesktop}px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (max-width: ${desktop}px) {
    border-left: none;
    border-top: 1px solid #d8d8d8;
    padding: 25px;
  }

  @media (max-width: ${tablet}px) {
    padding: 15px;
  }
`

export const InnerColumn = styled.div`
  max-width: 550px;
  width: 100%;

  @media (max-width: ${desktop}px) {
    max-width: unset;
  }
`
