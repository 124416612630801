import * as React from "react"
import styled, { keyframes } from "styled-components"
import * as colors from "../../constants/colors"

const defaultSize = 22
const defaultBorderSize = 3

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const SpinnerIcon = styled.div`
  border: ${({ borderSize }) => (borderSize ? borderSize : defaultBorderSize)}px solid
    ${colors.offGrey};
  border-top: ${({ borderSize }) => (borderSize ? borderSize : defaultBorderSize)}px solid
    ${colors.darkBorderGrey};
  height: ${({ size }) => (size ? size : defaultSize)}px;
  width: ${({ size }) => (size ? size : defaultSize)}px;
  position: absolute;
  left: calc(50% - ${({ size }) => (size ? size / 2 : defaultSize / 2)}px);
  top: calc(50% - ${({ size }) => (size ? size / 2 : defaultSize / 2)}px);
  animation: ${rotate} 0.6s linear infinite;
  -webkit-animation: ${rotate} 0.6s linear infinite;
  border-radius: 50%;
`

const Overlay = styled.div`
  height: ${({ isLocal }) => (isLocal ? "100%" : "100vh")};
  width: ${({ isLocal }) => (isLocal ? "100%" : "100vw")};
  z-index: 1000;
  background-color: ${colors.white};
  position: ${({ isLocal }) => (isLocal ? "absolute" : "fixed")};
  opacity: 0.85;
  top: 0;
  left: 0;
`

const Label = styled.p`
  font-size: 0.92857143em;
  position: absolute;
  top: calc(50% + 20px);
  left: 50%;
  transform: translate(-50%);
`

interface Props {
  isLoading: boolean
  label?: string
  isLocal?: boolean
}

const Spinner = ({ isLoading, label, isLocal }: Props) => {
  return isLoading ? (
    <Overlay isLocal={isLocal}>
      <SpinnerIcon />
      {label && <Label>{label}</Label>}
    </Overlay>
  ) : null
}

export default Spinner
