import * as React from "react"
import AddressType from "../../../../model/AddressType"
import OutlinedInput from "../../OutlinedInput"
import FormFieldProps from "../../FormFieldProps"

const FirstNameField = ({ onChange, onBlur, state, validate }: FormFieldProps<AddressType>) => {
  return (
    <OutlinedInput
      name="firstName"
      onChange={onChange}
      value={state.firstName}
      label="First Name"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={FirstNameField.isValid(state)}
    />
  )
}

FirstNameField.isValid = state => {
  return state.firstName
}

export default FirstNameField
