import "semantic-ui-css/semantic.min.css"
import "./src/style/index.scss"
import "./src/style/blog.scss"
import "whatwg-fetch"
import "react-datepicker/dist/react-datepicker.css"
import { track } from "./src/tracking/tracking"
import sbjs from "sourcebuster"
import smoothscroll from "smoothscroll-polyfill"
import searchInsights from "search-insights"
import * as tracker from "./src/social-supermarket/tracking/tracker"
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

import "swiper/css/bundle"

const React = require("react")

import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper"
import GlobalProvider from "./src/social-supermarket/context/GlobalProvider"
import { isRunTime } from "./src/social-supermarket/util/generalUtil"
SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y])

sbjs.init()
smoothscroll.polyfill()
if (isRunTime()) {
  searchInsights("init", {
    appId: "BLA29WX2H4",
    apiKey: "57e3ad11e188f44c2c948e1ebced094f",
    useCookie: true,
  })
}

export const wrapRootElement = ({ element, props }) => (
  <GlobalProvider {...props}>{element}</GlobalProvider>
)

export const onPreRouteUpdate = () => {
  tracker.prePageLoad()
}

// You can delete this file if you're not using it
