export const getDaysAgo = (date: string): number => {
  let today = new Date()
  let createdOn = new Date(date)
  let msInDay = 24 * 60 * 60 * 1000

  createdOn.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)

  return Math.floor((+today - +createdOn) / msInDay)
}

export const formatDate = dateString => {
  const date = new Date(dateString)
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

export const isDateInTheFuture = dateString => {
  const [year, month, day] = dateString.split("-")
  const inputDate = new Date(year, month - 1, day)
  const currentDate = new Date()
  return inputDate > currentDate
}

export const formatDateIso = (date: Date) => {
  return date.toISOString().split("T")[0]
}

export const getBusinessDaysAhead = (startDate: Date, days: number): Date => {
  return addWorkDays(startDate, days)
}

export const getDaysAhead = (start: Date, days: number): Date => {
  return addDays(start, days)
}

const getTwoDigitsFormat = (value: number): number | string => (value < 10 ? `0${value}` : value)

export const checkIsDeliveryEnabled = (date: Date, bankHolidays: string[]): boolean => {
  const day = date.getDay()
  const year = date.getFullYear()
  const month = getTwoDigitsFormat(date.getMonth() + 1)
  const dateValue = getTwoDigitsFormat(date.getDate())
  const formattedDate = `${year}-${month}-${dateValue}`

  return day !== 0 && day !== 6 && !bankHolidays.includes(formattedDate)
}

const addWorkDays = (startDate: Date, daysToAdd: number): Date => {
  let dow = startDate.getDay()

  if (dow === 0) {
    daysToAdd++
  }

  if (dow + daysToAdd >= 6) {
    let remainingWorkDays = daysToAdd - (5 - dow)
    daysToAdd += 2
    if (remainingWorkDays > 5) {
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5)
      if (remainingWorkDays % 5 === 0) {
        daysToAdd -= 2
      }
    }
  }
  startDate.setDate(startDate.getDate() + daysToAdd)
  return startDate
}

const addDays = (startDate: Date, daysToAdd: number): Date => {
  startDate.setDate(startDate.getDate() + daysToAdd)
  return startDate
}

const compareByDate = (a, b) => {
  if (a.date < b.date) return 1
  if (a.date > b.date) return -1
  return 0
}

export const sortByDate = array => {
  return array.sort(compareByDate)
}
