import local from "./local"
import dev from "./dev"
import uat from "./uat"
import prod from "./prod"

export const getConfig = () => {
  if (typeof window === "undefined") {
    return prod
  }

  const { href } = window.location

  if (href.includes("locallocal.")) {
    return local
  } else if (href.includes("localdev.")) {
    return dev
  } else if (
    href.includes("localuat.") ||
    href.includes("gatsbyjs.io") ||
    href.includes("gtsb.io") ||
    href.includes("qa.soc") ||
    href.includes("localhost")
  ) {
    return uat
  } else {
    return prod
  }
}

export const isProdEnv = () => {
  if (typeof window === "undefined") {
    return false
  }

  const { href } = window.location

  return !(
    href.includes("locallocal.") ||
    href.includes("localdev.") ||
    href.includes("localuat.") ||
    href.includes("gatsbyjs.io") ||
    href.includes("gtsb.io") ||
    href.includes("qa.soc")
  )
}

export const getFrontendUrl = () => {
  const { protocol, host } = window.location
  return `${protocol}//${host}`
}
