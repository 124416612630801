import * as React from "react"
import AddressType from "../../../../model/AddressType"
import OutlinedInput from "../../OutlinedInput"
import FormFieldProps from "../../FormFieldProps"

const LastNameField = ({ onChange, onBlur, state, validate }: FormFieldProps<AddressType>) => {
  return (
    <OutlinedInput
      name="lastName"
      onChange={onChange}
      value={state.lastName}
      label="Last Name"
      validate={validate}
      onBlur={onBlur}
      type="text"
      isValid={LastNameField.isValid(state)}
    />
  )
}

LastNameField.isValid = state => {
  return state.lastName
}

export default LastNameField
