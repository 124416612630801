import * as React from "react"
import { Context, FC, useState } from "react"
import { DEFAULT_SEARCH_CONTEXT_TYPE, SearchContextType } from "../model/SearchContextType"
import useQueryString from "../hooks/useQueryString"

export const SearchContext: Context<SearchContextType> = React.createContext<SearchContextType>({
  ...DEFAULT_SEARCH_CONTEXT_TYPE,
})

interface Props {
  children: React.ReactNode
}

const SearchProvider: FC<Props> = ({ children }) => {
  const [search, setSearch] = useQueryString("search", "")
  return <SearchContext.Provider value={{ search, setSearch }}>{children}</SearchContext.Provider>
}

export default SearchProvider
