import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
// @ts-ignore
import favicon from "../images/favicon.jpg"
import { safeHeDecode } from "../social-supermarket/util/generalUtil"

interface Props {
  description: string
  title: string
  lang?: string
  meta?: []
  stripe?: boolean
  skipTemplate?: boolean
  children?: React.ReactNode
  image?: string
}

function SEO({
  description,
  lang = "en",
  meta = [],
  title,
  stripe,
  skipTemplate,
  children,
  image,
}: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={safeHeDecode(title || "")}
      titleTemplate={skipTemplate ? "%s" : `%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="icon" href={favicon} />
      {stripe && <script src="https://js.stripe.com/v3/" />}

      <meta
        name="viewport"
        content="height=device-height,
                      width=device-width, initial-scale=1.0,
                      minimum-scale=1.0, maximum-scale=1.0,
                      user-scalable=no, target-densitydpi=device-dpi"
      />

      <meta name="description" content={stripHtml(metaDescription)} />
      <meta property="og:title" content={title} />
      {image && <meta property="og:image" content={image} />}
      <link rel="stylesheet" href="https://use.typekit.net/unl5gik.css" />
      <script src="https://cdn.lightwidget.com/widgets/lightwidget.js" />
      <script
        type="module"
        src="https://cdn.what3words.com/javascript-components@4.0.0/dist/what3words/what3words.esm.js"
      ></script>
      <script
        noModule
        src="https://cdn.what3words.com/javascript-components@4.0.0/dist/what3words/what3words.js?key=88L6D755"
      ></script>
      {children}
    </Helmet>
  )
}

const stripHtml = html => {
  return html.replace(/(<([^>]+)>)/gi, "")
}

export default SEO
