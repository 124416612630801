import * as React from "react"
import styled from "styled-components"
import ContactDetailsType from "../../../model/ContactDetailsType"
import EmailField from "./fields/EmailField"
import PhoneField from "./fields/PhoneField"

export const DEFAULT_CONTACT_DETAILS_STATE: ContactDetailsType = {
  email: "",
  phone: "",
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;

  button:last-of-type {
    margin-bottom: 20px;
  }
`

const Row = styled.div`
  display: flex;
`

const Left = styled.div`
  flex: 1;
  margin-right: 5px;
`

const Right = styled.div`
  flex: 1;
  margin-left: 5px;
`
const Hint = styled.div`
  margin-top: -10px;
  margin-bottom: 15px;
  font-size: 0.9em;
  color: hsla(0, 0%, 0%, 0.6);
`

const fields = [EmailField, PhoneField]

interface Props {
  onChange: (name: string, value: number | string | boolean) => void
  onBlur?: (name: string) => void
  state: ContactDetailsType
  validate: boolean
}

const ContactDetailsForm = ({ onChange, onBlur, state, validate }: Props) => {
  return (
    <Form>
      <Row>
        <Left>
          <EmailField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
        </Left>
        <Right>
          <PhoneField onChange={onChange} onBlur={onBlur} state={state} validate={validate} />
        </Right>
      </Row>
      <Hint>Phone number is used to provide courier updates where applicable.</Hint>
    </Form>
  )
}

ContactDetailsForm.isValid = state => {
  return fields.every(Field => Field.isValid(state))
}

export default ContactDetailsForm
