import React from "react"

export const parsePrice = price => {
  const p = parseFloat(`${price}`.replace("£", ""))
  return isNaN(p) ? 0 : p
}

export const formatPrice = price => {
  const priceFloat = parsePrice(price).toFixed(2)
  return `£${priceFloat}`
}

export const flatListToHierarchical = (
  data = [],
  { idKey = "key", parentKey = "parentId", childrenKey = "children" } = {}
) => {
  const tree = []
  const childrenOf = {}
  data.forEach(item => {
    const newItem = { ...item }
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
    childrenOf[id] = childrenOf[id] || []
    newItem[childrenKey] = childrenOf[id]
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem)
  })
  return tree
}

export const isGiftCard = code => {
  return /([A-Za-z0-9][A-Za-z0-9][A-Za-z0-9][A-Za-za-z0-9]-[A-Za-z0-9][A-Za-z0-9][A-Za-z0-9][A-Za-z0-9]-[A-Za-z0-9][A-Za-z0-9][A-Za-z0-9][A-Za-z0-9]-[A-Za-z0-9][A-Za-z0-9][A-Za-z0-9][A-Za-z0-9])/.test(
    code
  )
}

export const cleanUrl = url => {
  if (
    !url.includes("gifting.socialsupermarket.org") &&
    !url.includes("app.socialsupermarket.org")
  ) {
    return url.replace(/^.*\/\/[^\/]+/, "")
  } else {
    return url
  }
}

export const isFirstGiftItem = items => {
  return items.every(item => !item.giftBox && !item.giftBoxBox)
}

export const getCheapestProduct = products => {
  let cheapest = products[0]
  products.forEach(product => {
    if (parsePrice(product.price) < parsePrice(cheapest.price)) {
      cheapest = product
    }
  })
  return cheapest
}

export const getImpactSlugs = impactString => {
  return impactString
    ? Array.isArray(impactString)
      ? impactString
      : impactString.split(",").map(slug => slug.trim())
    : []
}

export const getBrand = product => {
  return (product.brands?.nodes?.length && product.brands.nodes[0]) || {}
}

export const getCategory = product => {
  return (product.productCategories?.nodes?.length && product.productCategories.nodes[0]) || {}
}

export const getPriceString = (product, variation) => {
  if (product.price) {
    return formatPrice(product.price)
  }

  if (variation && variation.price) {
    return variation.price
  }

  if (product.variations) {
    let lowestPrice = 999
    let highestPrice = 0
    product.variations.nodes.forEach(node => {
      if (node.price) {
        const p = parsePrice(node.price)
        if (p < lowestPrice) {
          lowestPrice = p
        }
        if (p > highestPrice) {
          highestPrice = p
        }
      }
    })

    if (lowestPrice === highestPrice) {
      return formatPrice(lowestPrice)
    } else {
      return `${formatPrice(lowestPrice)} - ${formatPrice(highestPrice)}`
    }
  }

  return ""
}

export const getShippingTotal = method => {
  const methodShipping = method?.html?.match(/\d+(?:\.\d+)?/g)
  return parsePrice(methodShipping && methodShipping.length > 0 ? methodShipping[0] : 0)
}

export const getShippingTax = method => {
  return Math.ceil(method.taxes["1"] * 100) / 100
}
