import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import { CardElement } from "@stripe/react-stripe-js"
import BasketTotals from "../../pages/basket/BasketTotals"
import { StripeCardElementOptions } from "@stripe/stripe-js"

const PaymentContainer = styled.div`
  position: relative;
`
const TotalsContainer = styled.div`
  padding-top: 15px;
  margin-bottom: 15px;
`
const PaymentInput = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
`

interface Props {
  clientSecret: string
  handleCardChange: (event: any) => void
}

const Payment: FC<Props> = ({ clientSecret, handleCardChange }) => {
  const options: StripeCardElementOptions = {
    hidePostalCode: true,
  }
  return (
    <PaymentContainer>
      <TotalsContainer>
        <BasketTotals showCountdown={false} />
      </TotalsContainer>
      {clientSecret && (
        <PaymentInput>
          <CardElement id="card-element" options={options} onChange={handleCardChange} />
        </PaymentInput>
      )}
    </PaymentContainer>
  )
}

export default Payment
